var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "voucher" },
    [
      _c(
        "HeaderBack",
        {
          attrs: { title: "返回" },
          on: {
            back: function ($event) {
              _vm.loading = true
              _vm.$emit("back")
            },
          },
        },
        [
          _c("template", { slot: "center" }, [
            _c("span", [_vm._v("申报凭证")]),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "left-content" },
          [
            _c(
              "a-menu",
              {
                attrs: { mode: "inline" },
                on: { click: _vm.menuCheck },
                model: {
                  value: _vm.current,
                  callback: function ($$v) {
                    _vm.current = $$v
                  },
                  expression: "current",
                },
              },
              _vm._l(_vm.menuList, function (item) {
                return _c(
                  "a-menu-item-group",
                  { key: item.title, attrs: { title: item.title } },
                  _vm._l(item.childer, function (child) {
                    return _c("a-menu-item", { key: child.type }, [
                      _vm._v(" " + _vm._s(child.name) + " "),
                    ])
                  }),
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "right-content" }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }